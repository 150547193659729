<template>
  <v-container
    fluid
    style="background-color:#f2f2f2 !important;min-height:100%;"
  >
    <!-- Filter Array -->
    <v-row
      class="ma-0 pa-0 px-2 mb-2"
      style="background-color:white !important;border: 1px solid teal;border-radius: 6px;"
    >
      <v-col cols="12" class="ma-0 pa-0 py-2 mr-2">
        <v-card flat style="background-color:transparent;">
          <v-card-title class="pa-0 pr-2">
            <date-range-picker
              @dateChange="onDateChange"
              initDateMode="thisWeek"
            />

            <div
              class="d-flex"
              :class="{
                'ml-0 mt-2': $vuetify.breakpoint.smAndDown,
                'ml-2': $vuetify.breakpoint.mdAndUp,
              }"
            >
              <properties-dropdown large v-model="propertiesFilterArr" type="phim-truong" />
            </div>

            <v-spacer />
            <v-btn
              class="ma-2 pa-1 px-2"
              outlined
              @click="showCalendar = !showCalendar"
              :color="showCalendar ? '#AD1457' : 'grey'"
              min-width="0"
            >
              <v-icon>mdi-calendar-clock</v-icon>
            </v-btn>

            <div
              :class="{
                'ml-0 mt-0': $vuetify.breakpoint.smAndDown,
                'ml-1': $vuetify.breakpoint.mdAndUp,
              }"
              style="width:100%;"
            >
              <filter-array
                :filters="statusList"
                @change-filters="updateFilter"
              ></filter-array>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Filter Array -->

    <v-row>
       <v-col
        :class="{
          'd-none': !allowShowCalendar,
        }"
        cols="12" md="12"
      >
        <mcv-calendar
          :data="displayCalendar"
          :allowShowEvent="true"
        ></mcv-calendar>
      </v-col>
      <v-col
      :class="{
          'd-none': !allowShowTable,
        }"
       cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title class="py-2 px-2 mb-1" dark style="color:teal;">
            <template>
              <v-btn
                color="teal"
                light
                :disabled="!allowApprove"
                style="color:white;"
                class="text-none mr-2 mb-2"
                @click="approveSessions('approves')"
              >
                <v-icon small class="mr-2">mdi-checkbox-marked-circle</v-icon>
                Chấp Nhận
              </v-btn>
              <v-btn
                color="error"
                light
                :disabled="!allowReject"
                style="color:white;"
                class="text-none mr-2 mb-2"
                @click="showReason = true"
              >
                <v-icon small class="mr-2">mdi-alert</v-icon>
                Từ Chối
              </v-btn>
              <v-btn
                color="#E53935"
                light
                :disabled="!allowDelete"
                style="color:white;"
                class="text-none mr-2 mb-2"
                @click="deleteSessions"
              >
                <v-icon small class="mr-2">mdi-delete</v-icon>
                Xóa
              </v-btn>
              <v-btn class="text-none mr-2 mb-2"
                dark color="green" 
                  @click="exportExcel">
                  <v-icon class="mr-2">
                    mdi-download
                  </v-icon>
                  Download
              </v-btn>
            </template>
            <v-spacer />
            <v-text-field
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              outlined
              dense
              single-line
              hide-details
              style="max-width:320px;"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-data-table
              flat
              v-model="selected_sessions"
              :headers="headers"
              :items="sessionsAfterFilter"
              :loading="loading"
              loading-text="Loading... Please wait"
              no-data-text="Chưa có lượt thuê nào"
              item-key="_id"
              :mobile-breakpoint="0"
              :items-per-page="pageSize"
              show-select
              single-select
              dense
              class="elevation-0"
              :search="searchString"
              :page.sync="depsPage"
              @page-count="pageCount = $event"
              hide-default-footer
              :sort-by="['start_time']"
              :sort-desc="[true]"
            >
              <template v-slot:item.room="{ item }">
                <div
                  style="cursor:pointer;"
                  @click="gotoSession('/studios-sessions/' + item._id)"
                >
                  {{ item.properties.map((p) => p.name).join(", ") }}
                </div>
              </template>
              <template v-slot:item.start_time="{ item }">
                {{ $moment(item.start_time).format("HH:mm DD/MM/YY") }}
              </template>
              <template v-slot:item.end_time="{ item }">
                {{ $moment(item.end_time).format("HH:mm DD/MM/YY") }}
              </template>
              <!-- <template v-slot:item.created_at="{ item }">
                {{ $moment(item.created_at).format("HH:mm DD/MM/YYYY") }}
              </template> -->
              <template v-slot:item.status="{ item }">
                <div
                  style="font-size:12px;font-weight:600;"
                  :style="{ color: getStatusColor(item.status) }"
                >
                  {{ getStatusName(item.status) }}
                </div>
              </template>
              <template v-slot:item.program="{ item }">
                <div
                  style="font-size:12px;font-weight:600;"
                >
                  {{ item.program }}
                </div>
              </template>
            </v-data-table>
            <div v-if="pageCount > 1" class="text-center pt-2">
              <v-pagination
                v-model="depsPage"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <reason-dialog
      :show="showReason"
      :title="'Lý do từ chối'"
      @fill-reason-done="fillReason"
      @cancel-fill-reason="
        showReason = false;
        reason = '';
      "
    />
  </v-container>
</template>

<script>
let XLSX = require("xlsx");
import { mapGetters } from "vuex";
import PropertiesDropdown from "@/components/Properties/Studios/StudiosDropdown.vue";
import mixins_room from "@/mixins/Rooms/index.js";

export default {
  computed: {
    ...mapGetters({
      token: "token",
      userId: "userId",
    }),
    sessionsAfterFilter() {
      let self = this;
      let result = self.sessions;

      // Filter of Time
      if (result.length > 0 && self.range.start > 0) {
        let filter = [];
        filter = result.filter(
          (s) =>
            (this.$moment(this.range.start).valueOf() <=
              this.$moment(s.start_time).valueOf() &&
              this.$moment(s.start_time).valueOf() <=
                this.$moment(this.range.end).valueOf()) ||
            (this.$moment(this.range.start).valueOf() <=
              this.$moment(s.end_time).valueOf() &&
              this.$moment(s.end_time).valueOf() <=
                this.$moment(this.range.end).valueOf())
        );
        result = filter;
      }

      // Filter of Status
      if (result.length > 0) {
        let filter = [];
        filter = result.filter((s) => self.filtersResult.includes(s.status));
        result = filter;
      }

      //Filter of Properties
      if (result.length > 0) {
        let filter = [];
        filter = result.filter(
          (s) =>
            self.propertiesFilterArr.filter((i) =>
              s.properties_id.includes(i.id)
            ).length > 0
        );
        result = filter;
      }

      return result;
    },
    allowApprove: function() {
      let allow = true;
      if (!this.selected_sessions || this.selected_sessions.length <= 0) {
        allow = false;
      }
      this.selected_sessions.forEach((session) => {
        if (["APPROVED", "REJECTED", "CANCELLED"].includes(session.status)) {
          allow = false;
        }
      });
      return allow;
    },
    allowReject() {
      let allow = true;
      if (!this.selected_sessions || this.selected_sessions.length <= 0) {
        allow = false;
      }
      this.selected_sessions.forEach((session) => {
        if (["REJECTED", "CANCELLED"].includes(session.status)) {
          allow = false;
        }
      });
      return allow;
    },
    allowDelete() {
      let allow = true;
      if (!this.selected_sessions || this.selected_sessions.length <= 0) {
        allow = false;
      }
      return allow;
    },
    allowShowTable() {
      return !this.showCalendar;
    },
    allowShowCalendar() {
      return this.showCalendar;
    },
    displayCalendar() {
      let result = [];
      
      let sessionsAfFilter = this.sessionsAfterFilter;
        if (!sessionsAfFilter || sessionsAfFilter.length <= 0) return [];
        sessionsAfFilter.forEach((item) => {
          let startTime = this.$moment(item.start_time).format(
            "YYYY-MM-DD HH:mm"
          );
          let endTime = this.$moment(item.end_time).format("YYYY-MM-DD HH:mm");
          if (
            this.$moment(item.start_time).valueOf() <
            this.$moment(this.range.start).valueOf()
          ) {
            startTime = this.$moment(this.range.start).format(
              "YYYY-MM-DD HH:mm"
            );
          }
          if (
            this.$moment(item.end_time).valueOf() >
            this.$moment(this.range.end).valueOf()
          ) {
            endTime = this.$moment(this.range.end).format("YYYY-MM-DD HH:mm");
          }

          let content = item.properties_arr ? item.properties_arr[0].name : "-";
          content = content + " - " + item.description;

          result.push({
            data: item,
            name: content,
            start: startTime,
            end: endTime,
            color: item.properties_arr
              ? item.properties_arr[0].color
              : "#263238",
            timed: true,
          });
        });

      return result;
    },
  },
  components: {
    PropertiesDropdown,
  },
  mixins: [mixins_room],
  data() {
    return {
      roles: ['lich-quay-manager', 'vehicles-manager', 'admin'],
      sessions: [],
      selected_sessions: [],
      reason: "",
      showReason: false,
      properties: [],
      propertiesFilterArr: [],
      departments: [],
      users: [],
      pageSize: 20,
      pageCount: 0,
      depsPage: 1,
      loading: false,
      searchString: "",
      headers: [
        {
          text: "Phim trường",
          align: "start",
          sortable: true,
          value: "room",
          width: 180,
        },
        {
          text: "Người Thuê",
          align: "start",
          sortable: true,
          value: "user_name",
        },
        {
          text: "Thời Gian Bắt Đầu",
          align: "start",
          sortable: true,
          value: "start_time",
        },
        {
          text: "Thời Gian Kết Thúc",
          align: "start",
          sortable: true,
          value: "end_time",
        },
        {
          text: "Trạng Thái",
          align: "center",
          sortable: true,
          value: "status",
          width: 100,
        },
        {
          text: "Chương trình",
          align: "center",
          sortable: true,
          value: "program",
        },
      ],
      statusList: [
        {
          selected: true,
          code: "WAIT_FOR_APPROVE",
          label: "Chờ duyệt",
          color: "orange",
        },
        { selected: true, code: "APPROVED", label: "Đã duyệt", color: "green" },
        {
          selected: false,
          code: "REJECTED",
          label: "Đã từ chối",
          color: "red",
        },
        {
          selected: false,
          code: "CANCELLED",
          label: "Đã hủy",
          color: "#888888",
        },
        {
          selected: false,
          code: "COMPLETED",
          label: "Hoàn thành",
          color: "#4A148C",
        },
      ],
      filtersResult: [],
      menuDatePicker: false,
      rangeMode: "day",
      range: {
        start: this.$moment()
          .startOf("day")
          .toDate(),
        end: this.$moment()
          .endOf("day")
          .toDate(),
      },
      showCalendar: false,
    };
  },
  methods: {
    onDateChange(dateRange) {
      console.log("onDateChange");
      this.range = dateRange.range;
      this.rangeMode = dateRange.rangeMode;
      this.fetchAllData();
    },
    updateFilter(filters) {
      this.filtersResult = filters;
    },
    gotoSession(path) {
      this.$router.push({ path: path });
    },
    getStatusName(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.label;
      return "";
    },
    getStatusColor(code) {
      let matchStatus = this.statusList.find((status) => status.code == code);
      if (matchStatus) return matchStatus.color;
      return "black";
    },
    setColorForStudio() {
      let self = this;
      self.properties.forEach((property, idx) => {
        if (self.roomsColorArr[idx]) {
          property.color = self.roomsColorArr[idx];
        } else {
          property.color = "#263238";
        }
      });
    },
    processAfterFetchData() {
      if (this.sessions && this.sessions.length > 0) {
        this.sessions = this.sessions.filter((s) => !s.deleted);
        this.sessions.map((item) => {
          //Property
          item.properties_arr = [];
          if (this.properties.find((i) => item.properties_id.includes(i._id))) {
            item.properties_arr = this.properties
              .filter((i) => item.properties_id.includes(i._id))
              .map(({ code, name, color }) => ({ code, name, color }));
          }

          //Users
          if (!this.users.find((i) => i._id == item.user_id)) {
            item.user_name = "";
          } else {
            item.user_name = this.users.find(
              (i) => i._id == item.user_id
            ).fullname;
          }

          //Departments
          if (!this.departments.find((i) => i._id == item.department_id)) {
            item.department_name = "-";
          } else {
            item.department_name = this.departments.find(
              (i) => i._id == item.department_id
            ).name;
          }
        });
      }
    },
    fetchAllData() {
      let self = this;
      self.loading = true;
      self.sessions = [];
      self.properties = [];
      self.departments = [];
      self.users = [];
      self.selected_sessions = [];
      let paramsSessions = {
        // created_at_min: moment(self.params.timeRange.start).toISOString(),
        // created_at_max: moment(self.params.timeRange.end).toISOString(),
        // show_deleted: true,
        type: "thue-phim-truong",
      };
      let paramsProperties = {
        // created_at_min: moment(self.params.timeRange.start).toISOString(),
        // created_at_max: moment(self.params.timeRange.end).toISOString(),
        // show_deleted: true,
        enable: true,
        type: 'phim-truong'
      };

      Promise.all([
        self.axios.get(self.$root.apiAssetMana + "/sessions", {
          headers: { "x-auth": this.token },
          params: paramsSessions,
        }),
        self.axios.get(self.$root.apiAssetMana + "/properties", {
          headers: { "x-auth": this.token },
          params: paramsProperties,
        }),
        self.axios.get(
          self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId
        ),
        self.axios.get(
          self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId,
          {
            params: { showEnable: "true" },
          }
        ),
      ])
        .then((responseArray) => {
          if (responseArray[0].data.status == "OK") {
            self.sessions = responseArray[0].data.content.items;
          }
          if (responseArray[1].data.status == "OK") {
            self.properties = responseArray[1].data.content.items;
          }
          if (responseArray[2].data.status == "OK") {
            self.departments = responseArray[2].data.content.items;
          }
          if (responseArray[3].data.status == "OK") {
            self.users = responseArray[3].data.content.items;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // self.processTotalReport();
          self.setColorForStudio();
          self.processAfterFetchData();
          self.loading = false;
        });
    },
    fillReason(value) {
      this.reason = value;
      this.showReason = false;
      this.approveSessions("rejects");
    },
    async approveSessions(lockFunction) {
      if (!this.selected_sessions || this.selected_sessions.length <= 0) return;
      let validateSes = await this.validateTimeBeforeApprove();
      if (lockFunction == "approves" && !validateSes) {
        alert("Thời gian đặt phim trường đã bị trùng!");
        return;
      }

      let self = this;
      let reqData = {
        ids: this.selected_sessions
          .filter((s) => !["CANCELLED"].includes(s.status))
          .map((u) => u._id),
      };

      switch (lockFunction) {
        case "approves":
          reqData.confirmedBy = this.userId;
          reqData.ids = this.selected_sessions
            .filter(
              (s) => !["CANCELLED", "REJECTED", "APPROVED"].includes(s.status)
            )
            .map((u) => u._id);
          break;

        case "rejects":
          reqData.rejectedBy = this.userId;
          reqData.rejected_reason = this.reason;
          break;
      }

      this.axios
        .put(self.$root.apiAssetMana + "/sessions/" + lockFunction, reqData, {
          headers: { "x-auth": this.token },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            alert("Thành công!");
            self.fetchAllData();
          } else {
            alert("Đã xảy ra lỗi!");
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          alert("Đã xảy ra lỗi!");
          console.log(err);
        });
    },
    lockDepts(lockFunction) {
      if (!this.selected_sessions || this.selected_sessions.length <= 0) return;
      let self = this;
      this.axios
        .put(
          self.$root.apiAssetMana + "/sessions/" + lockFunction,
          {
            ids: this.selected_sessions.map((u) => u._id),
          },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            alert("Thành công!");
            self.fetchAllData();
          } else {
            alert("Đã xảy ra lỗi!");
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          alert("Đã xảy ra lỗi!");
          console.log(err);
        });
    },
    validateTimeBeforeApprove() {
      return new Promise((resolve) => {
        if (this.selected_sessions.length > 0) {
          let resultSessions = this.selected_sessions.filter(
            (s) =>
              !["CANCELLED", "REJECTED", "APPROVED", "COMPLETED"].includes(
                s.status
              )
          );
          if (resultSessions.length <= 0) return resolve(true);
          //Case 1: conflict sanme time with other session of selected
          resultSessions.forEach((i) => {
            let result = resultSessions.filter(
              (s) =>
                i._id != s._id &&
                this.$moment(i.start_time).format("DD/MM/YYYY") ==
                  this.$moment(s.start_time).format("DD/MM/YYYY") &&
                ((this.$moment(s.start_time).valueOf() <=
                  this.$moment(i.start_time).valueOf() &&
                  this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf()) ||
                  (this.$moment(s.start_time).valueOf() <=
                    this.$moment(i.end_time).valueOf() &&
                    this.$moment(i.end_time).valueOf() <=
                      this.$moment(s.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.start_time).valueOf() &&
                    this.$moment(s.start_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf() &&
                    this.$moment(s.end_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()))
            );
            if (result.length > 0) return resolve(false);
          });

          //Case 2: conflic with Sessions approved
          let sessionsApproved = this.sessions.filter(
            (s) => s.status == "APPROVED"
          );
          if (sessionsApproved.length <= 0) return resolve(true);
          sessionsApproved.forEach((i, idx) => {
            let result = resultSessions.filter(
              (s) =>
                i._id != s._id &&
                s.properties_id.filter((prop) => i.properties_id.includes(prop))
                  .length > 0 &&
                ((this.$moment(i.start_time).valueOf() <=
                  this.$moment(s.start_time).valueOf() &&
                  this.$moment(s.start_time).valueOf() <=
                    this.$moment(i.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf() &&
                    this.$moment(s.end_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()) ||
                  (this.$moment(i.start_time).valueOf() <=
                    this.$moment(s.end_time).valueOf() &&
                    this.$moment(s.start_time).valueOf() <=
                      this.$moment(i.end_time).valueOf()))
            );

            if (result.length > 0) {
              console.log("Tim Trungf:", i);
              return resolve(false);
            }

            if (idx == sessionsApproved.length - 1) return resolve(true);
          });
        } else {
          return true;
        }
      });
    },
    deleteSessions() {
      if (confirm("Bạn có chắc muốn xóa các mục đã chọn ?")) {
        if (!this.selected_sessions || this.selected_sessions.length <= 0)
          return;
        let self = this;
        this.axios
          .delete(self.$root.apiAssetMana + "/sessions/deletes", {
            headers: { "x-auth": this.token },
            data: {
              ids: this.selected_sessions.map((u) => u._id),
            },
          })
          .then((res) => {
            if (res.data.status == "OK") {
              alert("Xóa thành công!");
              self.fetchAllData();
            } else {
              alert("Đã xảy ra lỗi!");
              console.log(res.data.message);
            }
          })
          .catch((err) => {
            alert("Đã xảy ra lỗi!");
            console.log(err);
          });
      }
    },
    genExcelFileName() {
      let filename = "lich su thue phim truong ";
      filename =
      filename +
      " - " +
      this.$moment(this.range.start).format("DD-MM-YYYY") +
      " den " +
      this.$moment(this.range.end).format("DD-MM-YYYY") +
      ".xlsx";
      return filename;
    },
    exportExcel() {
        let wb = XLSX.utils.book_new();
        let data = [];
        let header = this.headers.map((theader) => theader.text);
        let valueKey = this.headers.map((theader) => theader.value);
        this.sessionsAfterFilter.forEach((session) => {
          let returnRow = {};
          for(let i=0; i<header.length; i++) {
            if(valueKey[i]=='room'){
              returnRow[header[i]] = session.properties.map((p) => p.name).join(", ") 
            } 
            else if(valueKey[i]=='status'){
              returnRow[header[i]] = this.getStatusName(session.status)
            } 
            else if(valueKey[i]=='start_time'){
              returnRow[header[i]] = this.$moment(session.start_time).format("HH:mm DD/MM/YY")
            } 
            else if(valueKey[i]=='end_time'){
              returnRow[header[i]] = this.$moment(session.end_time).format("HH:mm DD/MM/YY")
            } 
            else {
              returnRow[header[i]] = session[valueKey[i]]
            }
              
          }

          data.push(returnRow)
        });

        let dataWS = XLSX.utils.json_to_sheet(data, {
          header: header,
        });
        let wscols = [{ wpx: 160 }, { wpx: 160 }, {wpx: 120} ];
        header.forEach((f, index) => {
          if (index > 2) wscols.push({ wpx: 120 });
        });
        dataWS["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, dataWS, "Report");
        const fileName = this.genExcelFileName();
        XLSX.writeFile(wb, fileName);
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style></style>
