<template>
  <div class="props-drop-down pa-0" style="line-height:0;width:100%;">
    <v-menu
      v-model="showMenu"
      bottom
      :close-on-click="false"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          color="black"
          :block="fullWidth"
          outlined
          v-bind="attrs"
          v-on="on"
          :height="large ? 40 : 32"
          :disabled="disabled"
          class="text-none px-2 mx-0"
          style="border-radius:6px;min-width:320px;"
          :style="{
            'background-color': disabled ? '#F6F6F6 !important' : '#FFFFFF',
            color: disabled ? '#AAAAAA !important' : '',
          }"
        >
          <div style="width:calc(100% - 18px);" class="text-left">
            <span>
              {{ display_text }}
            </span>
          </div>

          <div style="width:18px;" class="text-right">
            <v-icon
              style="font-size:18px;"
              class="mr-2"
              :style="{
                color: disabled ? '#AAAAAA !important' : '',
              }"
            >
              mdi-chevron-down
            </v-icon>
          </div>
        </v-btn>
      </template>

      <v-card class="pa-0">
        <v-card-title class="py-1" style="border-bottom: 1px solid #CCCCCC;">
          <v-btn text color="green" class="text-none" @click="closeMenu">
            Xong
          </v-btn>
          <v-spacer />

          <v-btn
            text
            color="#333333"
            class="text-none"
            @click="setAllItemSelected"
          >
            Select All
          </v-btn>
          <v-btn
            text
            color="#333333"
            class="text-none"
            @click="selected_items = []"
          >
            Select None
          </v-btn>
        </v-card-title>
        <div class="py-2  d-flex" style="width:100%;">
          <v-spacer></v-spacer>
          <v-text-field
            class="pr-2"
            v-model="searchString"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            single-line
            hide-details
            clearable
            dense
            style="max-width:300px;"
          ></v-text-field>
        </div>
        <v-card-text class="pa-0 pb-2" style="height: 300px;overflow-y: scroll;">
          <v-data-table
            v-model="selected_items"
            :headers="headers"
            :items="displayItems"
            :items-per-page="50"
            :search="searchString"
            :loading="loading"
            loading-text="Loading... Please wait"
            dense
            style="padding:6px;max-width:420px;"
            :show-select="true"
            hide-default-header
            hide-default-footer
            :page.sync="depsPage"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
          </v-data-table>
          <div v-if="pageCount > 1" class="text-center pt-2">
            <v-pagination v-model="depsPage" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    text: { default: "", type: String },
    disabled: { default: false, type: Boolean },
    large: { default: false, type: Boolean },
    placeholder: { default: "Chọn phim trường", type: String },
    mandatory: { default: false, type: Boolean },
    fullWidth: { default: false, type: Boolean },
    type: { default: 'phim-truong', type: String },
    // items: {
    //   default: function() {
    //     return [];
    //   },
    //   type: Array,
    // },
  },
  computed: {
    display_text: function() {
      if (this.selected_items.length > 0) {
        if (this.selected_items.length == 1) {
          return this.selected_items[0].display_name;
        }

        if (this.selected_items.length == this.items.length) {
          return "Tất cả phim trường";
        }

        if (this.selected_items.length >= 1) {
          return " " + this.selected_items.length + " phim trường";
        }
      }
      return "Chọn phim trường";
    },
    displayItems() {
      let self = this;
      if (self.searchString) {
        return self.items.filter((i) =>
          i.display_name.includes(self.searchString)
        );
      } else {
        return self.items;
      }
    },
  },
  data() {
    return {
      showMenu: false,
      pageCount: 0,
      depsPage: 1,
      items: [
        // {
        //    dispaly_name: '',
        //    item_name: ''
        // }
      ],
      selected_items: [],
      searchString: "",
      loading: false,
      headers: [
        {
          text: "",
          value: "display_name",
          align: "start",
          class: " font-weight-bold dateMinWidth",
          sortable: true,
          width: "100",
        },
      ],
    };
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
      this.emitSelectedItems();
    },
    emitSelectedItems() {
      this.$emit("input", this.selected_items);
    },
    fetchAllProperties() {
      let self = this;
      self.items = [];
      self.selected_items = [];
      let paramsProperties = {
        // created_at_min: moment(self.params.timeRange.start).toISOString(),
        // created_at_max: moment(self.params.timeRange.end).toISOString(),
        // show_deleted: true,
        enable: true,
        type: 'phim-truong'
      };
      self.axios
        .get(self.$root.apiAssetMana + "/properties", {
          params: paramsProperties,
        })
        .then((res) => {
          if (res.data.status == "OK") {
            let customers = res.data.content.items
            customers = customers.filter(item => item.type == self.type)
            customers.forEach((customer) => {
              if (!customer.is_owner) {
                self.items.push({
                  id: customer._id,
                  display_name: customer.name,
                  // item_name: employee.item_name
                });
              }
            });
            self.setAllItemSelected();
            self.emitSelectedItems();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setAllItemSelected() {
      this.selected_items = this.items;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchAllProperties();
    });
  },
};
</script>

<style>
.props-drop-down .v-btn::before {
  background-color: #ffffff !important;
}

.props-drop-down .v-btn i:hover {
  background-color: #ffffff !important;
}

.props-drop-down .v-btn--outlined {
  border: 1px solid #999999 !important;
}

.props-drop-down .v-btn--outlined:hover {
  border: 2px solid #444444 !important;
}
</style>
